













































































































































































#tsp-text {
  h1 {
    @apply text-3xl my-6;
  }

  h2 {
    @apply text-2xl my-5;
  }

  h3 {
    @apply text-xl my-4;
  }

  h4 {
    @apply text-lg my-3;
  }

  h5 {
    @apply text-base my-2;
  }

  h6 {
    @apply text-sm my-1;
  }

  section {
    @apply my-4;
  }

  p {
    @apply my-3;
  }

  blockquote {
    @apply px-4 py-2 border border-l-4 border-current rounded;
  }

  a {
    @apply border-current border-b-2 transition ease-in-out duration-150;
    &:hover {
      @apply text-blue-600;
    }
  }

  ul {
    @apply list-outside list-disc pl-4;
  }

  ol {
    @apply list-outside list-decimal pl-4;
  }

  table {
    @apply table-fixed border-collapse my-3 border-2 border-current;
    th,
    td {
      @apply border border-current px-4 py-2;
    }
  }
}

@media print {
  input,
  button,
  nav {
    display: none;
  }
  #tsp-text {
    page-break-before: always;
    & > section {
      page-break-before: always;
    }
    blockquote {
      @apply py-0 border-0 border-l-4 rounded-none;
    }
  }
}

@variants hover, focus {
  .translate-r-2px {
    transform: translateX(2px) !important;
  }
  .translate-t-2px {
    transform: translateY(-2px) !important;
  }
  .translate-tr-2px {
    transform: translateX(2px) translateY(-2px) !important;
  }
  .transition-fast {
    transition: all 0.2s ease !important;
  }
}
